import React from "react";
import "./index.css";
import SingleBanner from "./SingleBanner";
import {Link}from "react-router-dom"

const Banners = ({ t }) => {
  return (
    <>
      <div className="container" style={{display:"flex",justifyContent:"center"}}>
        <div className="benefits-container">
          <Link to={''} >
          <div className="benefit-item">
            <div className="benefit-icon">✉️</div>
            <div className="benefit-text">
              <div className="benefit-title">FREE DELIVERY</div>
              <div className="benefit-description">For all orders over $120</div>
            </div>
          </div>
          </Link>
          <div className="benefit-item">
            <div className="benefit-icon">💳</div>
            <div className="benefit-text">
              <div className="benefit-title">SAFE PAYMENT</div>
              <div className="benefit-description">100% secure payment</div>
            </div>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon">💬</div>
            <div className="benefit-text">
              <div className="benefit-title">24/7 HELP CENTER</div>
              <div className="benefit-description">Dedicated 24/7 support</div>
            </div>
          </div>
          <div className="benefit-item">
            <div className="benefit-icon">📄</div>
            <div className="benefit-text">
              <div className="benefit-title">FRIENDLY SERVICES</div>
              <div className="benefit-description">30 day satisfaction guarantee</div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="category-section" style={{textAlign:"center", margin:"10px", padding:"10px", fontSize:"18px"}}>
        Our Categories Item List
        <div className="category-container">
        
          <div className="category-item vegetables">
          <Link to={'/apparel-mens'}>
            <div className="icon-circle">🥬</div>
            <div className="category-name">Apparel - Men's</div>
            </Link>
          </div>
        

          <div className="category-item fresh-bakery">
            <div className="icon-circle">🍞</div>
            <div className="category-name">Apparel - Women's</div>
          </div>
          <div className="category-item fresh-meat">
            <div className="icon-circle">🥩</div>
            <div className="category-name">Beauty</div>
          </div>
          <div className="category-item fresh-milk">
            <div className="icon-circle">🥛</div>
            <div className="category-name">Computers</div>
          </div>
          <div className="category-item sea-foods">
            <div className="icon-circle">🦞</div>
            <div className="category-name">Luxury</div>
          </div>
          <div className="category-item fruit-drink">
            <div className="icon-circle">🍹</div>
            <div className="category-name">Retail</div>
          </div>
          <div className="category-item green-salad">
            <div className="icon-circle">🥗</div>
            <div className="category-name">Household</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banners;