import React, { useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { cartOperations } from "../../state/ducs/cart";
import { globalOperations } from "../../state/ducs/globalState";

const NewArrivalsCard = (props) => {
  const navigate = useNavigate(); // Moved useNavigate to the top level of the component
  const [hover, setHover] = useState(false);

  const detailsClickHandler = () => {
    props.setSelectedProductVendorName(props.productObj.vendor_name);
  };

  const addToCartHandler = () => {

    // const isAuthenticated = props.isAuthenticated;
    // console.log(isAuthenticated);
    // if (!isAuthenticated) {
    //   navigate('/login');
    //   return;
    // }

    const other_vendor_products = props.cartItems.filter(
      (item) => item.vendor_name !== props.productObj.vendor_name
    );

    if (other_vendor_products.length) {
      alert("You can order single vendor product only at a time");
      return;
    }

    detailsClickHandler();
    props.addToCart(props.productObj);
    props.showAddToCartModal();
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const cardStyle = {
    border: '1px solid #ddd',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease',
    position: 'relative',
    marginBottom: '20px',
    cursor: 'pointer',
  };

  const imageStyle = {
    width: '100%',
    height: 'auto',
    transition: 'transform 0.3s ease',
  };

  const imageHoverStyle = {
    transform: 'scale(1.05)',
  };

  const toolsStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '50px',
    padding: '5px',
    display: hover ? 'flex' : 'none',
    justifyContent: 'center',
    gap: '10px',
  };

  const toolIconStyle = {
    color: '#555',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  };

  const overlayStyle = {
    padding: '15px',
    textAlign: 'center',
  };

  const starsStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '10px',
  };

  const starStyle = {
    color: '#ffd700',
    margin: '0 2px',
  };

  const titleStyle = {
    color: '#333',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
  };

  const titleHoverStyle = {
    color: '#007bff',
  };

  const priceStyle = {
    color: '#ff5722',
    marginBottom: '15px',
    fontSize: '1.2em',
  };

  const addCartStyle = {
    backgroundColor: '#b80ebb',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    display: 'inline-block',
  };

  const addCartHoverStyle = {
    backgroundColor: '#0056b3',
  };

  return (
    <div
      style={cardStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Link onClick={detailsClickHandler} to={"/productDetails/" + props.productObj.id}>
        <img
          src={"https://ap.jakhantakhan.com/assets/images/products/" + props.productObj.photo}
          style={hover ? { ...imageStyle, ...imageHoverStyle } : imageStyle}
          alt="Banner Image"
        />
      </Link>
      <div style={toolsStyle}>
        <i className="fa fa-heart" style={toolIconStyle}></i>
        <i className="fa fa-exchange" style={toolIconStyle}></i>
        <i className="fa fa-search" style={toolIconStyle}></i>
      </div>
      <div style={overlayStyle}>
        <div style={starsStyle}>
          <i className="fa fa-star" style={starStyle}></i>
          <i className="fa fa-star" style={starStyle}></i>
          <i className="fa fa-star" style={starStyle}></i>
          <i className="fa fa-star" style={starStyle}></i>
          <i className="fa fa-star" style={starStyle}></i>
        </div>
        <h5 style={titleStyle}>
          <Link onClick={detailsClickHandler} to={"/productDetails/" + props.productObj.id} style={titleStyle}>
            {props.productObj.name.substr(0, 16)}
          </Link>
        </h5>
        <h5 style={priceStyle}>{`৳${numberWithCommas(props.productObj.price)}`}</h5>
        <div
          onClick={addToCartHandler}
          style={addCartStyle}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = addCartHoverStyle.backgroundColor)}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = addCartStyle.backgroundColor)}
        >
          {props.t("addc")}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = {
  addToCart: cartOperations.addToCart,
  showAddToCartModal: globalOperations.showAddToCartModal,
  setSelectedProductVendorName: globalOperations.setSelectedProductVendorName,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewArrivalsCard);
