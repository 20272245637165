import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import "./index.css";
import NewArrivalsCard from "../../Components/NewArrivals/NewArrivalsCard";

const SearchResults = ({ t, vendor_name }) => {


  const {searchKeyword, categoryId}= useParams();

  const [newArrivalProducts, setnewArrivalProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  

  useEffect(() => {
    
      const searchProducts = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://ap.jakhantakhan.com/api/categorySearch?category_id=${categoryId || ''}&search=${searchKeyword}`);
        const data = await response.json();
        setnewArrivalProducts(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    searchProducts();
  }, [searchKeyword, categoryId]);

  let productsToShow = <h1>Loading...</h1>;

  if (newArrivalProducts.length) {
    productsToShow = newArrivalProducts.slice(0, 20).map((product) => (
      <div className="col-md-3">
        <NewArrivalsCard
          productObj={product}
          title={product.name}
          price={`৳${product.price}`}
          image={product.photo}
          t={t}
        />
      </div>
    ));
  } else if(!loading) {
    productsToShow = <h1>No products to show</h1>;
  }
 
  return (
    <div className="categoryDetailsContainer">
      <div className="container">
        <div className="row">

          <div className="col-md-10">
            <h1>Search Results for: {searchKeyword}</h1>
          </div>


        </div>
        <div className="row">
        <div className="row gy-5">
      
        {productsToShow}

        </div>
      </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vendor_name: state.globalState.selectedProductVendorName
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
