import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { cartOperations } from "../../state/ducs/cart";
import { numberWithCommas } from "../../utils";
import "./index.css";

const CustomToggle = ({ children, passedStep, eventKey, callback, unref }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.log("totally custom!");
    callback && callback(eventKey);
  });

  return (
    <div className="">
      <button
        className="collapseButton"
        type="button"
        ref={unref}
        style={{
          backgroundColor: "transparent",
          border: "none",
          outline: "none",
          width: "100%",
          height: "100%",
          textAlign: "left",
          padding: "20px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={decoratedOnClick}
        disabled={eventKey > passedStep}
      >
        {children}
        {eventKey <= passedStep && <i className="fa fa-chevron-down mx-2"></i>}
      </button>
    </div>
  );
};

const CardComponent = (props) => {
  return (
    <Card>
      <Card.Header>
        <CustomToggle
          unref={props.unref}
          passedStep={props.passedStep}
          eventKey={props.eventKey}
        >
          <h5>{props.heading}</h5>
        </CustomToggle>
      </Card.Header>
      <Accordion.Collapse
        // appear={props.passedStep == props.eventKey}
        eventKey={props.eventKey}
      >
        <Card.Body>{props.children}</Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const CheckoutPage = ({ cartItems, clearCart, userInfo }) => {

  console.log("cartItems", cartItems);
  const [passedStep, setPassedStep] = useState(0);

  const [userName, setUserName] = useState(() => {
    const nameParts = userInfo.name?.split(" ") || [];

    const firstName = nameParts.slice(0, -1).join(" "); // "MD Emon"
    const lastName = nameParts[nameParts.length - 1] || ""; // "Howlader"

    return { firstName, lastName };
  });


  const [email, setEmail] = useState(userInfo.email || "");
  const [company, setCompany] = useState(userInfo.company || "");
  const [address, setAddress] = useState(userInfo.address || "");
  const [city, setCity] = useState(userInfo.city || "");
  const [zip, setZip] = useState(userInfo.zip || "");
  const [country, setcountry] = useState(userInfo.country || "");
  const [password, setPassword] = useState(userInfo.password || "");

  let subtotalPrice = 0;
  for (let index = 0; index < cartItems.length; index++) {
    const element = cartItems[index];
    subtotalPrice += parseInt(element.price) * element.quantity;
  }
  const navigate = useNavigate();

  const onOrderSubmitHandler = () => {


    // const orderObj = {
    //   userName: userName.firstName + userName.lastName,
    //   email: email,
    //   password: password,
    //   total: subtotalPrice + 1000, //1000 flat rate
    //   cartItems: cartItems,
    //   pay_amount: "float",
    //   paymentMethod: "string",
    //   customer_email: "email",
    //   customer_name: "string",
    //   customer_phone: "customer_phone",



    // };

    const orderObj2 = {
      user_id: userInfo.id,
      ordered_products: cartItems.map(item => ({
        id: item.id,
        name: item.name,
        price: item.price,
        totalQuantity: item.quantity,
        totalPrice: item.price * item.quantity,
      })),

      pay_amount: subtotalPrice,
      paymentMethod: "COD",
      customer_email: userInfo.email,
      customer_name: userInfo.name,
      customer_phone: userInfo.phone,
      customer_address: userInfo.address,
      customer_country: userInfo.country,
      customer_city: userInfo.city,
      customer_zip: userInfo.zip,
      vendor_name: cartItems[0].vendor_name

    }

    const Ids = cartItems.filter(item => item.cart_id).map(item => item.cart_id);

    axios
      .post("https://ap.jakhantakhan.com/api/order/store", orderObj2)
      .then(function (response) {

        console.log(response);
        if (response.status === 200) {
          // alert("Order Placed Successfully");
          // clearCart();
          // navigate("/myAccounts");




          if (Ids.length > 0) {

            axios.delete('https://ap.jakhantakhan.com/api/cart-items', {
              data: {
                ids: Ids, // Pass the array of cart item ids to be deleted
              },
            })
              .then(function (response) {
                console.log(response);
                if (response.status === 200) {
                  clearCart();
                  navigate("/myAccounts");
                  // navigate("/");
                }
              })
              .catch(function (error) {
                console.log(error);
              });

          } else {
            clearCart();
            navigate("/myAccounts");
            // navigate("/");
          }



        } else if (response.status === 400) {
          alert(response.message);
          clearCart();
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Order Failure");
      });
  };

  const ref0 = useRef(null);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  // const ref4 = useRef(null);
  // const ref5 = useRef(null);

  useEffect(() => {
    if (passedStep === 1) {
      ref1.current.click();
    }
    else if (passedStep === 2) {
      ref2.current.click();
    }
    else if (passedStep === 3) {
      ref3.current.click();
    }
    // else if (passedStep === 4) {
    //   ref4.current.click();
    // } 
    // else {
    //   ref5.current.click();
    // }
  }, [passedStep]);

  const onFirstContinueClickHandler = (num) => {
    setPassedStep(parseInt(num) + 1);
  };

  const accordArray = [
    {
      num: "0",
      title: "Step 1: Billing Details",
      innerComponent: (
        <div className="row">
          <div className="col-md-6">
            <form>
              <fieldset id="personal-details">
                <legend className="legender">Your Personal Details</legend>

                <div className="form-group row">
                  <label
                    htmlFor="input-firstname"
                    className="col-sm-4 col-form-label"
                  >
                    First Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="firstname"
                      placeholder="First Name"
                      id="input-firstname"
                      className="form-control"
                      value={userName.firstName}
                      onChange={(e) =>
                        setUserName({ ...userName, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="input-lastname"
                    className="col-sm-4 col-form-label"
                  >
                    Last Name
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="lastname"
                      placeholder="Last Name"
                      id="input-lastname"
                      className="form-control"
                      value={userName.lastName}
                      onChange={(e) =>
                        setUserName({ ...userName, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="input-email"
                    className="col-sm-4 col-form-label"
                  >
                    E-Mail
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="email"
                      name="email"
                      placeholder="E-Mail"
                      id="input-email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="input-telephone"
                    className="col-sm-4 col-form-label"
                  >
                    Telephone
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="telephone"
                      placeholder="Telephone"
                      id="input-telephone"
                      className="form-control"
                      value={userInfo.phone || ""}
                    />
                  </div>
                </div>
              </fieldset>

              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="newslatter"
                  id="same-address"
                  value={1}
                />
                <label className="form-check-label" htmlFor="same-address">
                  My delivery and billing addresses are the same.
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="newslatter"
                  id="subscribe-newsletter"
                  value={0}
                />
                <label
                  className="form-check-label"
                  htmlFor="subscribe-newsletter"
                >
                  I wish to subscribe to the Faiza Inc newsletter.
                </label>
              </div>
            </form>
          </div>

          <div className="col-md-6">
            <form>
              <fieldset id="address-details">
                <legend className="legender">Your Address</legend>

                <div className="form-group row">
                  <label
                    htmlFor="input-company"
                    className="col-sm-4 col-form-label"
                  >
                    Company
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="company"
                      placeholder="Company"
                      id=""
                      className="form-control"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="input-address1"
                    className="col-sm-4 col-form-label"
                  >
                    Address
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="address1"
                      placeholder="Address 1"
                      id="input-address1"
                      className="form-control"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
                </div>

                {/* <div className="form-group row">
                  <label
                    htmlFor="input-address2"
                    className="col-sm-4 col-form-label"
                  >
                    Address 2
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="address2"
                      placeholder="Address 2"
                      id="input-address2"
                      className="form-control"
                      value={userInfo.address || ""}
                    />
                  </div>
                </div> */}

                <div className="form-group row">
                  <label
                    htmlFor="input-city"
                    className="col-sm-4 col-form-label"
                  >
                    City
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="city"
                      placeholder="City"
                      id="input-city"
                      className="form-control"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="input-zipcode"
                    className="col-sm-4 col-form-label"
                  >
                    Post Code
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="zipcode"
                      placeholder="Post Code"
                      id="input-zipcode"
                      className="form-control"
                      value={zip}
                      onChange={(e) => setZip(e.target.value)}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    htmlFor="input-country"
                    className="col-sm-4 col-form-label"
                  >
                    Country
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="country"
                      placeholder="Country"
                      id="input-country"
                      className="form-control"
                      value={country}
                      onChange={(e) => setcountry(e.target.value)}
                    />
                  </div>
                </div>

                {/* <div className="form-group row">
                  <label
                    htmlFor="input-region"
                    className="col-sm-4 col-form-label"
                  >
                    Region / State
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      name="region"
                      placeholder="Region / State"
                      id="input-region"
                      className="form-control"
                      value={userInfo.city || ""}
                    />
                  </div>
                </div> */}

                <div className="" style={{ display: "flex", justifyContent: "end" }}>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (!userName.firstName.length) {
                        return;
                      }
                      onFirstContinueClickHandler("0");
                    }}
                    className="btn btn-primary float-right"
                  >
                    Continue
                  </button>
                  <div className="form-check mt-3">
                    {/* <input
                      className="form-check-input"
                      type="checkbox"
                      name="agree"
                      id="agree-policy"
                      value={1}
                    /> */}
                    {/* <label className="form-check-label" htmlFor="agree-policy">
                      I have read and agree to the
                      <a href="" className="agree">
                        <b> Privacy Policy </b>
                      </a>
                    </label> */}
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      ),
    },

    // {
    //   num: "2",
    //   title: "Step 3: Delivery Details",
    //   innerComponent: (
    //     <div className="container">
    //       <p>Enter your destination to get a shipping estimate</p>
    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>First Name</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <input
    //             className="shipping"
    //             placeholder="Enter your first name"
    //             type="text"
    //             value={userInfo.name?.split(" ")[0] || ""}
    //           />
    //           {/* <select
    //             className="shipping"
    //             name="shippingCountry"
    //             id="shippingCountry"
    //           >
    //             Shipping Country
    //             <option value="japan">Japan</option>
    //             <option value="france">France</option>
    //             <option value="bangladesh">Bangladesh</option>
    //             <option value="india">India</option>
    //           </select> */}
    //         </div>
    //       </div>
    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>Last Name</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <input
    //             className="shipping"
    //             placeholder="Enter your last name"
    //             type="text"
    //             value={userInfo.name?.split(" ")[1] || ""}
    //           />
    //           {/* <select
    //             className="shipping"
    //             name="shippingRegion"
    //             id="shippingRegion"
    //           >
    //             Shipping Region
    //             <option value="japan">Japan</option>
    //             <option value="france">France</option>
    //             <option value="bangladesh">Bangladesh</option>
    //             <option value="india">India</option>
    //           </select> */}
    //         </div>
    //       </div>
    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>Email</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <input
    //             className="shipping"
    //             placeholder="Enter email address"
    //             type="email"
    //             value={userInfo.email || ""}
    //           />
    //         </div>
    //       </div>

    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>Address 1</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <input
    //             className="shipping"
    //             placeholder="Address 1"
    //             type="text"
    //             value={userInfo.address || ""}
    //           />
    //         </div>
    //       </div>

    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>Address 2</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <input
    //             className="shipping"
    //             placeholder="Address 2"
    //             type="text"
    //             value={userInfo.address || ""}
    //           />
    //         </div>
    //       </div>

    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>City</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <input
    //             className="shipping"
    //             placeholder="Dhaka"
    //             type="text"
    //             value={userInfo.city || ""}
    //           />
    //         </div>
    //       </div>

    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>Post Code</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <input
    //             className="shipping"
    //             placeholder="1207"
    //             type="text"
    //             value={userInfo.zip || ""}
    //           />
    //         </div>
    //       </div>

    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>Country</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           {/* <input
    //             className="shipping"
    //             placeholder="Enter your last name"
    //             type="text"
    //           /> */}
    //           <select
    //             className="shipping"
    //             name="shippingRegion"
    //             id="shippingCountry"
    //             value={userInfo.country || null}
    //           >
    //             Shipping Region
    //             <option value="japan">Japan</option>
    //             <option value="france">France</option>
    //             <option value="Bangladesh">Bangladesh</option>
    //             <option value="india">India</option>
    //           </select>
    //         </div>
    //       </div>

    //       <div className="row pb-3">
    //         <div className="col-md-2">
    //           <p>Region / State</p>
    //         </div>
    //         <div className="col-md-10 d-flex justify-content-center align-items-center">
    //           <select
    //             className="shipping"
    //             name="shippingRegion"
    //             id="shippingState"
    //             value={userInfo.country || null}
    //           >
    //             Shipping Region
    //             <option value="Bangladesh">Bangladesh</option>
    //             <option value="france">India</option>
    //             <option value="bangladesh">USA</option>
    //             <option value="india">Kyoto</option>
    //           </select>
    //         </div>
    //       </div>
    //       <div className="w-100 d-flex justify-content-end align-items-center">
    //         <button
    //           onClick={() => onFirstContinueClickHandler("2")}
    //           className="getQuotesBtn"
    //         >
    //           Continue
    //         </button>
    //       </div>
    //     </div>
    //   ),
    // },

    // {
    //   num: "2",
    //   title: "Step 2: Delivery Method",
    //   innerComponent: (
    //     <div className="row">
    //       <div className="col-md-6">
    //         <p>
    //           Please select the preferred shipping method to use on this order.
    //         </p>
    //         <p>Flat Rate</p>
    //         <div class="form-check">
    //           <input
    //             class="form-check-input"
    //             type="radio"
    //             name="flexRadioDefault"
    //             id="flexRadioDefault1"
    //             checked
    //           />
    //           <label class="form-check-label" for="flexRadioDefault1">
    //             Flat Shipping Rate ৳5000
    //           </label>
    //         </div>
    //       </div>
    //       <div className="w-100 d-flex justify-content-end align-items-center">
    //         <button
    //           onClick={() => onFirstContinueClickHandler("3")}
    //           className="getQuotesBtn"
    //         >
    //           Continue
    //         </button>
    //       </div>
    //     </div>
    //   ),
    // },

    {
      num: "1",
      title: "Step 2: Payment Method",
      innerComponent: (
        <div className="row">
          <div className="col-md-6">
            <p>Please select the preferred payment method.</p>
            <div class="form-check">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                Cash On Delivery
              </label>
            </div>
          </div>
          <div className="w-100 d-flex justify-content-end align-items-center">
            <button
              onClick={() => onFirstContinueClickHandler("1")}
              className="getQuotesBtn"
            >
              Continue
            </button>
          </div>
        </div>
      ),
    },

    {
      num: "2",
      title: "Step 3: Confirm Order",
      innerComponent: (
        <div class="container">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Vendor Name</th>
                <th>Quantity</th>
                <th>Unit Price </th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.length &&
                cartItems.map((product) => (
                  <tr>
                    <td>{product.name}</td>
                    <td>{product.vendor_name}</td>
                    <td>{product.quantity}</td>
                    <td>৳{numberWithCommas(product.price)}</td>
                    <td>
                      ৳{numberWithCommas(product.price * product.quantity)}
                    </td>
                  </tr>
                ))}
              <tr>
                <td align="right" colSpan={4}>
                  Sub-total:
                </td>
                <td>৳{subtotalPrice}</td>
              </tr>
              <tr>
                <td align="right" colSpan={4}>
                  Flat Shipping Rate:
                </td>
                <td>৳0</td>
              </tr>
              <tr>
                <td align="right" colSpan={4}>
                  Total:
                </td>
                <td>৳{+subtotalPrice + 0}</td>
              </tr>
            </tbody>
          </table>

          <div className="w-100 d-flex justify-content-end align-items-center">
            <button
              onClick={() => {
                onFirstContinueClickHandler("2");
                onOrderSubmitHandler();
              }}
              className="getQuotesBtn"
            >
              Confirm Order
            </button>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* <CartBannerComponent title="Checkout" /> */}
      <div className="container chkout">
        <div className="row">
          <h1>Checkout</h1>
          <Accordion defaultActiveKey="0">
            {accordArray.map(({ num, title, innerComponent }) => (
              <CardComponent
                passedStep={passedStep}
                eventKey={num}
                unref={
                  num === "0"
                    ? ref0
                    : num === "1"
                      ? ref1
                      : num === "2"
                        ? ref2
                        : num === "3"
                          ? ref3
                          // : num === "4"
                          // ? ref4
                          // : num === "5"
                          // ? ref5
                          : ref0
                }
                heading={title}
              >
                {innerComponent}
              </CardComponent>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
    userInfo: state.auth.user
  };
};

const mapDispatchToProps = {
  clearCart: cartOperations.clearCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
